<template>
  <v-footer dark padless>
    <v-card width="100vw" flat tile class="wrapper">
      <v-row class="row">

        <v-col cols="4" class="column-no-padding-bottom">
          <v-img src="../static/LOGO BRANCA.png" contain width="130" height="90" class="img" />
        </v-col>

        <v-col cols="8" class="column-no-padding-bottom">
          <v-row class="row">
            <v-col cols="12" class="column">
              <div class="right-text">
                <v-btn icon class="btn-left" target="_blank" href="https://api.whatsapp.com/send?phone=554788428900">
                  <v-icon size="50px">
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
                <v-btn icon class="btn-left" target="_blank" href="https://www.linkedin.com/in/caroline-araujo-a88842204">
                  <v-icon size="50px">
                    mdi-linkedin
                  </v-icon>
                </v-btn>
                <v-btn icon class="btn-right" target="_blank" href="https://www.instagram.com/arq.carolinearaujo/">
                  <v-icon size="50px">
                    mdi-instagram
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" class="column">
              <v-card-text class="right-text">
                arq.carolinear@gmail.com
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="column-no-padding">
          <div class="centered-text">
            {{ new Date().getFullYear() }} — Caroline Araujo Arquitetura ©
          </div>
        </v-col>
        <!-- <v-col cols="12" class="column-no-padding">
          <div class="centered-text">
            Desenvolvido por <a href="https://github.com/patrickpiotrowski" target="_blank" class="link">Patrick Piotrowski</a>
          </div>
        </v-col> -->
      </v-row>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
  }),
}
</script>

<style scoped>
* {
  font-weight: 600;
}
.wrapper {
  display: flex;
  background-color: #476a30;
}

.img {
  margin: 15px 15px 15px 10px;
}

.centered-text {
  text-align: center;
  padding: 0 0 10px 0;
  margin: -10px 0 0 0;
}
.centered-text a {
  color: #ffffff;
  font-size: inherit;
  text-decoration: underline;
}

.right-text {
  justify-content: center;
  text-align: right;
  padding: 20px 0 20px 0px;
}

.column {
  padding: 0 10px 0 0;
}
.column-no-padding {
  padding: 0;
}
.column-no-padding-bottom {
  padding-bottom: 0;
}

.btn-right {
  margin: 10px 4px 0 10px;
}

.btn-left {
  margin: 10px 10px 0 10px;
}

.row {
  margin: -12px 0 0 0;
}

</style>