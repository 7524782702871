<template>
  <v-container fluid>
    <div style="height: 80vh;">
      <h1>404 - Página não encontrada</h1>
      <h2>A página que você está procurando não existe.</h2>
      <v-img :src="imgSrc" height="100%" contain></v-img>
    </div>
  </v-container>
</template>

<script>
import img1 from '../static/404/not_found_1.png'
import img2 from '../static/404/not_found_2.png'
import img3 from '../static/404/not_found_3.png'

export default {
  data () {
    return {
      imgArray: [img1, img2, img3],
      imgSrc: ''
    };
  },
  mounted () {
    this.randomPhoto();
  },
  methods: {
    randomPhoto () {
      const max = 2;
      const min = 0;
      this.imgSrc = this.imgArray[Math.floor(Math.random() * (max - min + 1)) + min];
      console.log(this.imgSrc)
    }
  }
}
</script>