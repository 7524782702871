<template>
    <v-app-bar v-if="!mobile" app :height="topHeight" hide-on-scroll flat contain="true" width="100vw" color="rgba(255, 255, 255, 0)">
        <v-row class="row">
            <v-col :cols="colsImg" v-if="!this.$vuetify.theme.dark">
                <nav>
                    <router-link to="/">
                        <v-img contain src="../static/LOGO PRETA.png" width="130" height="90" />
                    </router-link>
                </nav>
            </v-col>
            <v-col :cols="colsImg" v-else>
                <nav>
                    <router-link to="/">
                        <v-img contain src="../static/LOGO BRANCA.png" width="130" height="90" />
                    </router-link>
                </nav>
            </v-col>
            <v-col :cols="colsMenu" class="flex-end">
                <the-dark-mode-switch></the-dark-mode-switch>
                <v-sheet class="mx-auto" :max-width="maxMenuWidth" color="rgba(255, 255, 255, 0)">
                    <v-slide-group multiple center-active active-class="active">
                        <v-slide-item v-for="path in paths" :key="path.name">
                            <nav>
                                <router-link :to="path.path">
                                        {{ path.name }}
                                </router-link>
                            </nav>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-col>
        </v-row>
    </v-app-bar>
    <div v-else>
        <v-app-bar app :height="topHeight" contain="true" width="100vw" hide-on-scroll>
            <div v-if="!this.$vuetify.theme.dark">
                <nav>
                    <router-link to="/">
                        <v-img contain src="../static/LOGO PRETA.png" width="130" height="90" />
                    </router-link>
                </nav>
            </div>
            <div v-else>
                <nav>
                    <router-link to="/">
                        <v-img contain src="../static/LOGO BRANCA.png" width="130" height="90" />
                    </router-link>
                </nav>
            </div>
            <v-spacer></v-spacer>
            <the-dark-mode-switch></the-dark-mode-switch>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer nav v-model="drawer" fixed temporary right color="#476a30">
            <div style="display: flex; height: 80%; align-items: center; justify-content: center;">
                <v-list width="100%">
                    <nav>
                        <v-list-item-group>
                            <div v-for="(path, i) in paths" :key="i" class="option">
                                <router-link :to="path.path">
                                        <v-list-item>
                                            <v-spacer></v-spacer>
                                            <p class="btn-drawer">{{ path.name }}</p>
                                            <v-spacer></v-spacer>
                                        </v-list-item>
                                </router-link>
                            </div>
                        </v-list-item-group>
                    </nav>
                </v-list>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
import TheDarkModeSwitch from './TheDarkModeSwitch.vue';
import { RouterLink } from 'vue-router';

export default {
    components: { RouterLink, TheDarkModeSwitch },
    data: () => ({
        drawer: null,
        group: null,
        colsMenu: 8,
        colsImg: 4,
        topHeight: '100px',
        mobile: false,
        maxMenuWidth: '700',
        paths: [
            {
                path: '/',
                name: 'HOME',
                active: false,
            },
            {
                path: '/sobre',
                name: 'SOBRE',
                active: false,
            },
            {
                path: '/projetos',
                name: 'PROJETOS',
                active: false,
            },
            {
                path: '/contato',
                name: 'CONTATO',
                active: false,
            },
        ]
    }),
    watch: {
        group() {
            this.drawer = false
        },
    },
    beforeMount() {
        this.handleDimensions();
    },
    mounted() {
        window.addEventListener('resize', this.handleDimensions);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleDimensions);
    },
    methods: {
        handleDimensions() {
            if (window.innerWidth < window.innerHeight) {
                this.colsImg = 12;
                this.colsMenu = 12;
                this.mobile = true;
            } else {
                this.colsImg = 4;
                this.colsMenu = 8;
                this.mobile = false;
            }
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.row {
    align-items: center;
    margin: 0;
}

nav a {
    font-family: 'Josefin Sans', sans-serif !important;
    font-weight: 200;
    font-size: 1.5rem;
    text-decoration: none;
    margin: 0 20px 0 20px;
}

nav a.router-link-exact-active {
    color: #476a30;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.option a {

    margin: 0 !important;
}

.mx-auto {
    background-color: #f5f5f5;
}

.v-application .mx-auto {
    margin: 0 !important;
}

.active {
    background-color: #476a30;
}
.btn-drawer {
    color: #FFFFFF; 
    font-size: 1.5rem;
    text-decoration: none;
}
a:link {
text-decoration:none;
}
a:visited {
text-decoration:none;
}
a:hover {
text-decoration:none;
}
</style>