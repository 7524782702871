import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgArray: {},
    imgURLS: []
  },
  getters: {
    getImgArray () {
      return this.state.imgArray;
    },
    getImgURLS () {
      return this.state.imgURLS;
    }
  },
  mutations: {
    setImgArray (state, obj) {
      state.imgArray = obj;
    },
    setImgURLS (state, array) {
      state.imgURLS = array;
    }
  },
  actions: {
  },
  modules: {
  }
})
