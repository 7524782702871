import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=486b49bd&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=js&"
export * from "./TheFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=486b49bd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486b49bd",
  null
  
)

export default component.exports