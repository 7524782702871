<template>
  <v-app class="app">
    <the-header></the-header>
    <v-main v-if="done">
      <router-view @reloadImg="updateImgs" class="router-view" />
      <the-footer></the-footer>
    </v-main>
    <div id="big-div" class="text-center" style="flex-direction: column;" v-else>
      <v-progress-circular size="150" indeterminate color="#476a30"></v-progress-circular>
      <!-- <EmManutencao></EmManutencao> -->
    </div>
  </v-app>
</template>

<script>
import axios from 'axios';

import TheFooter from './components/TheFooter.vue';
import TheHeader from './components/TheHeader.vue'
// import EmManutencao from './views/EmManutencao.vue';

export default {
  name: 'App',
  components: { TheHeader, TheFooter }, // EmManutencao },
  data() {
    return {
      done: false,
      filteredImages: {},
      filters: ['#revitalização', '#apto706', '#banheirocinza', '#quartocasal', '#casare', '#nailsdesign', '#office', '#espaçogourmet', '#banheirorosa', '#salaecozinha'], // adicionar por primeiro
      imgURLS: [],
      user_id: '7001521123214621',
      ACCESS_TOKEN: 'IGQWRQcmowMzZAoMHg3ZAXotTkMwNzFkbjk5UEtKLTVseWpsUlY1azVLRFF0eW16WHlaMEtpQk5BckVQS2JucFl3SHd1LXlnZADF1dzliTUtvemZA1ZAUxhYkhuOWViMzhyNHlKck8taVVyQ3pXUQZDZD',
      ignoreIds: ['18036016888707181'] // reel #quartocasal
    }
  },
  beforeMount() {
    for (const filter of this.filters) {    //initialize the object to hold an array for every filter
      this.filteredImages[filter] = [];
    }
  },
  mounted() {
    this.getImages();
  },
  methods: {
    updateImgs() {
      this.getImages();
    },
    async getImages() {
      let allImages = [];
      let url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp&access_token=${this.ACCESS_TOKEN}`;
      while (url) {
        await axios.get(url)
          .then(async (response) => {
            allImages = allImages.concat(response.data.data);
            url = response.data.paging && response.data.paging.next ? response.data.paging.next : null;
          });
      }
      for (const item of allImages) {
        for (const filter of this.filters) {
          if (item.caption.toLowerCase().includes(filter.toLowerCase())) {
            if (this.ignoreIds.includes(item.id)) { // if the id is in ignore list, ignore it
              continue;
            }
            if (item.caption.toLowerCase().includes('#carrosel')) {
              await this.getCarousel(item, filter);
            }
            else {
              this.filteredImages[filter].push(item); // if the caption includes the filter, the object is pushed to the specific filter array
            }
            this.imgURLS.push(item['media_url']);
          }
        }
      }
      this.shuffleArray(this.imgURLS);
      this.$store.commit('setImgURLS', this.imgURLS);
      this.$store.commit('setImgArray', this.filteredImages);
      this.done = true;
    },
    async getCarousel(item, filter) {
      axios
        .get(`https://graph.instagram.com/${item.id}/children?fields=id,media_url&access_token=${this.ACCESS_TOKEN}`)
        .then((response) => {
          for (const imagem of response.data.data) {
            imagem['timestamp'] = item.timestamp;
            this.filteredImages[filter].push(imagem);
            this.imgURLS.push(imagem['media_url']);
          }
        });
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


.router-view {
  margin-top: auto;
}

@font-face {
  font-family: "DinNext";
  src: local("DinNext"), url(./fonts/din-next-w10-ultralight.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "DinNext";
  src: local("DinNext"), url(./fonts/din-next-w10-medium.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "DinNext";
  src: local("DinNext"), url(./fonts/din-next-w10-bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation\ Hairline.otf) format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation\ Light.otf) format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation\ Black.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation\ Bold.otf) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Ablation";
  src: local("Ablation"), url(./fonts/ablation/Ablation\ ExtraBold.otf) format("opentype");
  font-weight: 900;
}

.app {
  font-family: 'Josefin Sans', sans-serif !important;
}

#big-div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
</style>
