<template>
  <v-btn @click="toggleDarkMode" depressed icon small>
    <v-icon v-if="!darkMode" >mdi-white-balance-sunny</v-icon>
    <v-icon v-else >mdi-weather-night</v-icon>
  </v-btn> 
</template>

<script>
export default {
  data () {
    return {
      darkMode: this.$vuetify.theme.dark
    }
  },
  created () {
    this.$vuetify.theme.dark = localStorage.getItem('darkMode');
    this.darkMode = this.$vuetify.theme.dark;
  },
  methods: {
    toggleDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
      localStorage.setItem('darkMode', this.$vuetify.theme.dark);
    }
  }
}
</script>